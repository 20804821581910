import { createRouter, createWebHistory } from 'vue-router'
import CrlsView from "../views/CrlsView.vue";

const routes = [
  {
    path: "/",
    name: "crls",
    component: CrlsView
  }
];


const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    console.log("scrollBehavior to: "+to.hash);
    if (to.hash) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
          })
        }, 500)
      })
    }
  }
})

export default router
