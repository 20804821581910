import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
});

export default store;