import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import 'vuetify/dist/vuetify.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS file

import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

console.log(vuetify);
const app = createApp(App);
//app.config.productionTip = false;
// Use app.use() to install plugins (router, store, vuetify)
app.use(router);
app.use(store);
app.use(vuetify);
// Mount the app
app.mount('#app');

