
import axios from 'axios';

axios.defaults.withCredentials = true;

const env = window.location.origin === "http://localhost:8080" ? "TEST" : "PRO";
const baseUrl = env === 'TEST' ? "http://localhost:9999" : "";

console.log("env is "+env+" baseUrl is: "+baseUrl);

export default { env, baseUrl };